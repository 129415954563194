html,
body {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  color: inherit;
  background-color: inherit;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
  font-size: min(max(calc(12px + 1.25vw), 4vw), 30px);
}

.team-register-wrapper {
  height: auto;
  width: 70vw;
  max-width: 100rem;
  margin: auto;
  border-radius: 30px;
  border-bottom-right-radius: 4px;
  box-shadow: 2vw 2vw #76879b;
  overflow: hidden;
  background-color: #d9d9d9;
}

#register {
  padding: 10px;
}
