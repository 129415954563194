html,
body {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  color: inherit;
  background-color: inherit;
}

body {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100% 100%;
  background-image: url('https://hophacks-website.s3.amazonaws.com/images/About.png'); 
  /* updated image */
  background-attachment: fixed;
  /* background-color: #e1f1ff; */
}

.section {
  width: 100%;
  min-width: none;
  min-height: none;
  text-align: left;
}

.profile {
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  min-width: none;
  box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
  min-height: none;
  backdrop-filter: blur(14px);
  background-color: #bfeafc;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
}

.section-header {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 1.5rem;
  color: #000;
}

.section-divider {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 0px;
  background-color: #000;
}

.table {
  margin-top: 3%;
  margin-bottom: 1%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.table-header {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: #000;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: auto;
  margin-left: auto;
}

.table-body {
  font-family: proxima-nova, sans-serif;
  color: #000;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: auto;
  margin-left: auto;
}
