html,
body {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  color: inherit;
  background-color: inherit;
}

body {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100% 100%;
  background-image: url('https://hophacks-website.s3.amazonaws.com/images/About.png');
  background-attachment: fixed;
  /* background-color: #0b7adc; */
}

.card {
  margin-top: 5%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5%;
  width: 70%;
  min-width: none;
  max-width: 700px;
  box-shadow: 15px 15px 0px rgba(45, 153, 224, 0.6);
  height: 90%;
  min-height: none;
  background-color: #e1f1ff;
  padding: 3%;
  text-align: center;
  border-radius: 25px;
}

.text-field {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 8px 8px 10px rgba(45, 153, 224, 0.4);
}

/* Targeted selectors */
.text-field:focus-within {
  border: 4px solid;
  border-color: rgb(21, 113, 69);
}

.card-title {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 2.5rem;
  color: #29a0e2;
  margin-top: '10%';
  margin-right: auto;
  margin-left: auto;
}

.card-subtitle {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #29a0e2;
  margin-right: auto;
  margin-left: auto;
}

.card-infoline {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #29a0e2;
  margin-right: auto;
  margin-left: auto;
}

.card-button {
  margin-top: 10%;
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  min-width: 200px;
  max-width: 200px;
  height: 60px;
  border-radius: 15px;
  background-color: #2d99e0;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: proxima-nova, sans-serif;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.card-button:hover {
  background-color: #061a40;
  border-color: #2d99e0;
  color: #fff;
}

.card-text {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.8rem;
  color: #fff;
  margin-top: 5%;
}

.card-text-blue {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  color: #2d99e0;
  margin-top: 5%;
}

.card-text-red {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.8rem;
  color: #ff0000;
  margin-top: 5%;
}

.motto-text {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 3rem;
  color: #fff;
  text-align: end;
  margin-top: 5%;
}

.motto-subtext {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  text-align: end;
  color: #fff;
  margin-top: 5%;
}

.mobile-header {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin-top: 5%;
}

/* TODO: may need to check these styles further */
.mobile-subheader {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  margin-top: 5%;
}

.mobile-infoline {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.5rem;
  color: #fff;
  text-align: center;
  margin-top: 5%;
}

.mobile-motto-text {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-top: 7%;
}

.returnToLoginButtonText {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.mobile-motto-subtext {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.8rem;
  text-align: center;
  color: #fff;
  margin-top: 2%;
}

.color-select {
  width: 50px;
  height: 50px;
  margin: 4px;
  border-radius: 20%;
  background-color: #000;
  cursor: 'pointer';
  border: 3px solid;
  border-color: #fff;
}

.color-select:focus {
  border: 4px solid;
  border-color: rgb(21, 113, 69);
}

.container {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
  font-size: min(max(calc(12px + 1.25vw), 4vw), 30px);
}

.personal-wrapper {
  padding: 50px;
}

.confirm-wrapper {
  padding-bottom: 25px;
}

.register-wrapper {
  height: auto;
  width: 80vw;
  max-width: 50rem;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: rgb(232, 235, 242);
}

#graphic {
  /* max-height: 25rem;
  max-width: 25rem; */
  min-height: 25rem;
  min-width: 25rem;
}

@media (max-width: 650px) {
  #graphic {
    min-height: 12rem;
    min-width: 12rem;
    display: block;
    margin: auto;
  }
}

#register {
  padding: 10px;
}

/* Phone Number CSS */
:root {
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 0.8em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-self: center;
  margin-top: 15px;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  align-self: center;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
